<template>
  <div>
    <v-row no-gutters>
      <v-col>
        <div id="account-transactions-list">
          <v-card
            tile
            :flat="$vuetify.breakpoint.mdAndDown"
            :class="$vuetify.breakpoint.mdAndDown ? '' : ' rounded-t-lg'"
          >
            <v-toolbar-title
              class="text-body-2 text-md-h6 font-weight-medium d-flex justify-space-between align-center"
            >
              <v-card-title class="align-start">
                <v-icon :large="!$vuetify.breakpoint.smAndDown" left>
                  {{ icons.mdiBankTransfer }}
                </v-icon>
                <span class="font-weight-semibold"> Son Hesap Hareketleri </span>
                <v-btn v-if="$vuetify.breakpoint.xsOnly" color="error" text @click="listHareketler"> TÜMÜNÜ GÖR </v-btn>
              </v-card-title>
              <v-btn
                v-if="!$vuetify.breakpoint.xsOnly"
                class="view-all-button"
                color="error"
                text
                @click="listHareketler"
              >
                TÜMÜNÜ GÖR
              </v-btn>
            </v-toolbar-title>

            <v-spacer></v-spacer>

            <!-- table -->

            <v-sheet :style="$vuetify.breakpoint.xsOnly ? 'padding-top:20px' : null">
              <v-data-table
                :item-key="'unique_id'"
                :headers="tableColumns"
                :items="dataListTable"
                :sort-desc="true"
                :loading="loading"
                fixed-header
                :items-per-page="10"
                hide-default-footer
                class="custom-data-table"
              >
                <template v-slot:item.islemTarihi="{ item }">
                  <div class="">
                    {{ item.islemTarihi | moment('Do MMMM YYYY HH:mm') }}
                  </div>
                </template>

                <template #item.BankaKodu="{ item }">
                  <div class="d-flex flex-column ms-3">
                    <span class="d-block font-weight-medium text-high-emphasis">{{ item.BankaKodu }}</span>
                    <small>{{ item.IBAN }}</small>
                  </div>
                </template>

                <template v-slot:item.tutar="{ item }">
                  <!--  <div class="">
                    {{ item.tutar | currency }}
                  </div> -->
                  <div class="d-flex justify-end" style="width: 100%">
                    <VChip
                      :color="item.tutar < 0 ? 'red' : 'green'"
                      label
                      outlined
                      small
                      class="custom-chip d-flex justify-end"
                      :style="{
                        width: '98px',
                        border: '2px solid',
                        borderColor: item.tutar < 0 ? 'red' : 'green',
                      }"
                    >
                      {{ item.tutar | currency({ symbol: item.dovizKodu }) }}
                    </VChip>
                  </div>
                </template>
                <template v-slot:item.aciklama="{ item }">
                  <div class="aciklama-cell">
                    {{ item.aciklama }}
                  </div>
                </template>
              </v-data-table>
            </v-sheet>
          </v-card>
        </div>
      </v-col>
    </v-row>
  </div>
</template>

<script>
import router from '@/router'
import store from '@/store'
import axios from '@axios'
import {
  mdiAccountCashOutline,
  mdiAccountOutline,
  mdiAccountReactivate,
  mdiArrowRightBold,
  mdiBankTransfer,
  mdiChevronDown,
  mdiChevronUp,
  mdiCircle,
  mdiDeleteOutline,
  mdiDotsVertical,
  mdiFileDocumentOutline,
  mdiFilter,
  mdiMagnify,
  mdiPoll,
} from '@mdi/js'
import { computed, onMounted, ref } from '@vue/composition-api'
import Vue from 'vue'
export default {
  data() {
    return {
      //banka: this.banka.value,
      tableItems: [],
      loading: false,

      icons: {
        mdiPoll,
        mdiFileDocumentOutline,
        mdiDotsVertical,
        mdiDeleteOutline,
        mdiCircle,
        mdiMagnify,
        mdiAccountOutline,
        mdiFilter,
        mdiBankTransfer,
        mdiAccountCashOutline,
        mdiAccountReactivate,
        mdiChevronDown,
        mdiChevronUp,
        mdiArrowRightBold,
      },
    }
  },

  setup() {
    const refetchData = hideOverlay => {
      setTimeout(() => {
        hideOverlay()
      }, 3000)
    }

    const loading = ref(false)

    const tableColumns = computed(() => [
      {
        text: 'İşlem Tarihi',
        value: 'islemTarihi',

        align: 'start',
        sortable: false,
        width: '5px',
      },
      {
        text: 'Banka',
        value: 'BankaKodu',
        width: '20px',
        align: 'start',
        sortable: false,
      },
      {
        text: 'Açıklama',
        value: 'aciklama',

        sortable: false,
        class: 'min-width-400',
        align: 'start',
        width: '60px',
      },
      {
        text: 'Tutar',
        value: 'tutar',

        align: 'end',
        sortable: false,
        width: '30px',
      },
    ])

    onMounted(() => {
      dataListTable.value = []
      fetchList()
    })

    const isCardDetailsVisible = true
    const dataListTable = ref([])

    const listHareketler = () => {
      router.push({ path: '/accounts/transactions/list/' })
    }

    const fetchList = async () => {
      // this.loading = true
      let company
      if (store.state.companyCustomer) {
        company = store.state.companyCustomer
      }
      const params = new URLSearchParams()
      params.append('method', 'getUserBankTransactions')

      params.append('web_api', 1)
      params.append('page', 1)
      params.append('limit', 50)
      params.append('company', company)
      axios
        .post('', params)
        .then(response => {
          if (response.error_code === 99) {
            Vue.swal({
              title: 'Oturum Sonlandı!',
              html: 'Geçersiz Token',
              icon: 'error',
              background: '#FF4C51',
              timerProgressBar: true,
              showConfirmButton: false,
            })
            router.push('/login')

            return
          }

          dataListTable.value = response.data.response.transactions
        })
        .catch(e => {
          console.log(e)
        })
        .finally(() => {
          loading.value = false
        })
    }

    const resolveStatusVariant = status => {
      if (status === 'TL') return { color: 'primary' }
      if (status === 'EUR') return { color: 'success' }
      if (status === 'USD') return { color: 'tertiary' }
      if (status === 'Resigned') return { color: 'warning' }

      return { color: 'info' }
    }

    return {
      refetchData,
      isCardDetailsVisible,
      fetchList,
      dataListTable,
      tableColumns,
      loading,
      listHareketler,
      resolveStatusVariant,
      icons: {
        mdiPoll,
        mdiFileDocumentOutline,
        mdiDotsVertical,
        mdiDeleteOutline,
        mdiCircle,
        mdiMagnify,
        mdiAccountOutline,
        mdiBankTransfer,
        mdiAccountCashOutline,
        mdiAccountReactivate,
        mdiChevronDown,
        mdiChevronUp,

        mdiArrowRightBold,
        mdiFilter,
      },
    }
  },
}
</script>

<style lang="scss">
@import '@core/preset/preset/apps/user.scss';

.v-data-footer {
  background-color: transparent !important;
}

/* Flex düzenindeki satırlara alt kenarlık ekleyin ve kalınlaştırın */
.v-data-table__mobile-table-row {
  border-bottom: 3px solid #dcdcdc; /* Satır alt kenarlığı */
}

/* İsterseniz satırın üst kısmına da kenarlık ekleyebilirsiniz */
.v-data-table__mobile-table-row:first-child {
  border-top: 0px solid #dcdcdc; /* İlk satırın üst kenarlığı */
}

/* Satırların kenarlarına padding ve margin ekleyerek görünümünü iyileştirin */
.v-data-table__mobile-table-row {
  padding: 10px 0; /* Satırlar arasına boşluk ekleyin */
}
</style>

<style scoped>
.justify-space-between {
  justify-content: space-between;
}
.v-data-table-header th {
  min-width: 100px;
  white-space: nowrap;
  border-bottom: 2px solid #dcdcdc; /* Başlık altı çizgisi rengini değiştirir */
}

.v-data-table-header th:first-child {
  padding-left: 2px;
}

.v-data-table-header th:last-child {
  padding-right: 2px;
}

.view-all-button {
  /* Varsayılan stil ayarları */
  font-size: 1rem; /* Büyük ekranlarda daha büyük */
  padding: 8px 16px; /* Büyük ekranlarda yeterli boşluk */
  white-space: nowrap; /* Metni tek satırda tutar */
  overflow: hidden; /* Taşan metni gizler */
  text-overflow: ellipsis; /* Taşan metin için üç nokta gösterir */
}
.aciklama-cell {
  min-width: 40px; /* Minimum genişlik */
  max-width: 400px; /* Opsiyonel: Maksimum genişlik */
  white-space: normal; /* Metinleri alt satıra taşır */
  word-wrap: break-word; /* Uzun kelimeleri böler */
}

@media (max-width: 600px) {
  .view-all-button {
    /* Mobil görünüm için stil ayarları */
    font-size: 0.75rem; /* Küçük yazı boyutu */
    padding: 4px 8px; /* Küçük boşluk */
    margin-left: 0; /* Solda boşluk bırakma */
    margin-right: auto; /* Sağda boşluk bırakma */
    display: block; /* Butonu blok düzende gösterme */
    text-align: left; /* Metni sola hizalama */
    white-space: normal; /* Metnin satır içinde taşmasını sağlar */
  }
}
.overflow-container {
  overflow-x: auto; /* Yatay kaydırma çubuğunu aktif et */
  -webkit-overflow-scrolling: touch; /* Dokunmatik kaydırmayı destekle */
}
.v-toolbar-title {
  overflow-x: auto; /* Yatay kaydırma çubuğunu aktif et */
  -webkit-overflow-scrolling: touch; /* Dokunmatik kaydırmayı destekle */
}
.custom-chip {
  font-size: 10px; /* İhtiyaca göre ayarla */
  height: 20px; /* İhtiyaca göre ayarla */
  line-height: 20px; /* İhtiyaca göre ayarla */
}
</style>
