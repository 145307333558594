<template>
  <div class="auth-wrapper auth-v2">
    <div class="auth-inner">
      <!-- brand logo -->
      <!--  <router-link to="/" class="brand-logo d-flex align-center">
        <v-img :src="require('@/assets/images/mavera_img.png')" alt="logo" contain class="me-3"></v-img>
      </router-link> -->
      <!--/ brand logo -->

      <v-row class="auth-row ma-0">
        <v-col lg="8" class="d-none d-lg-block position-relative overflow-hidden pa-0">
          <div class="auth-illustrator-wrapper">
            <!-- triangle bg -->
            <v-img class="kenburnsEffect" cover height="100%" src="@/assets/images/misc/finans_logosu.jpg"></v-img>
            <!--   <img
              height="362"
              class="auth-mask-bg"
              :src="require(`@/assets/images/misc/mask-v2-${$vuetify.theme.dark ? 'dark' : 'light'}.png`)"
            /> -->
          </div>
        </v-col>

        <v-col lg="4" class="d-flex align-center auth-bg pa-10 pb-0">
          <v-row>
            <v-col cols="12" sm="8" md="6" lg="12" class="mx-auto">
              <v-card flat>
                <div v-if="$vuetify.theme.dark" class="logo-container" style="text-align: center">
                  <img
                    src="@/assets/images/mavera_logo_beyaz.png"
                    width="200px"
                    alt="logo"
                    class="logo mb-0 mb-md-16"
                  />
                </div>
                <div v-else class="logo-container-dark" style="text-align: center">
                  <img
                    src="@/assets/images/mavera_logo_dark.png"
                    width="200px"
                    alt="logo"
                    class="logo-dark mb-0 mb-md-16"
                  />
                </div>

                <!--  <v-card-text class="pb-9">
                  <p class="text-2xl font-weight-semibold text--primary mb-2">Hoşgeldiniz</p>
                  <p class="mb-2">Lütfen hesabınıza giriş yapın</p>
                </v-card-text> -->

                <!-- login form -->
                <v-card-text>
                  <v-form ref="loginForm" @submit.prevent="handleFormSubmit">
                    <v-text-field
                      v-model="username"
                      outlined
                      label="Kullanıcı Adı"
                      placeholder="username"
                      :error-messages="errorMessages.username"
                      :rules="[validators.required]"
                      hide-details="auto"
                      class="mb-6"
                    ></v-text-field>

                    <v-text-field
                      v-model="password"
                      outlined
                      :type="isPasswordVisible ? 'text' : 'password'"
                      label="Şifre"
                      :error-messages="errorMessages.password"
                      placeholder="Şifreniz"
                      :append-icon="isPasswordVisible ? icons.mdiEyeOffOutline : icons.mdiEyeOutline"
                      :rules="[validators.required]"
                      hide-details="auto"
                      class="mb-2"
                      @click:append="isPasswordVisible = !isPasswordVisible"
                    ></v-text-field>
                    <v-checkbox v-model="remember" label="Beni Hatırla"></v-checkbox>
                    <div class="text-right">
                      <router-link :to="{ name: 'auth-forgot-password' }" class="ms-3"> Şifremi Unuttum? </router-link>
                    </div>

                    <v-btn block x-large color="primary" type="submit" class="text-capitalize mt-6" :loading="loading">
                      Giriş Yap
                    </v-btn>
                    <v-alert v-if="errorTxt" type="error" outlined text class="mt-3">
                      {{ errorTxt }}
                    </v-alert>
                  </v-form>
                </v-card-text>
              </v-card>
            </v-col>
          </v-row>
        </v-col>
      </v-row>
    </div>
  </div>
</template>

<script>
// eslint-disable-next-line object-curly-newline
import axios from '@axios'

import { useRouter } from '@core/utils'
import { required } from '@core/utils/validation'
import { mdiEyeOffOutline, mdiEyeOutline, mdiFacebook, mdiGithub, mdiGoogle, mdiTwitter } from '@mdi/js'
import themeConfig from '@themeConfig'
import { getCurrentInstance, ref } from '@vue/composition-api'

export default {
  setup() {
    // Template Ref
    const loginForm = ref(null)
    const errorTxt = ref()
    const vm = getCurrentInstance().proxy
    const { router } = useRouter()

    const isPasswordVisible = ref(false)
    const loading = ref(false)
    const username = ref(null)
    const password = ref(null)
    const remember = ref(false)

    const errorMessages = ref([])
    const socialLink = [
      {
        icon: mdiFacebook,
        color: '#4267b2',
        colorInDark: '#4267b2',
      },
      {
        icon: mdiTwitter,
        color: '#1da1f2',
        colorInDark: '#1da1f2',
      },
      {
        icon: mdiGithub,
        color: '#272727',
        colorInDark: '#fff',
      },
      {
        icon: mdiGoogle,
        color: '#db4437',
        colorInDark: '#db4437',
      },
    ]

    const handleFormSubmit = async () => {
      const isFormValid = loginForm.value.validate()

      if (!isFormValid) return

      loading.value = true
      errorTxt.value = ''
      const params = new URLSearchParams()
      params.append('method', 'login')
      params.append('UserName', username.value)
      params.append('Password', password.value)
      params.append('web_api', 1)

      try {
        const response = await axios.post('', params)
        if (response.data.error === 1) {
          // API'den dönen hata kodunu kontrol et
          errorTxt.value = response.data.msg // API'den dönen hata mesajını göster
        } else {
          const { accessToken } = response.data
          localStorage.setItem('accessToken', accessToken)

          const { user } = response.data
          const { ability: userAbility } = user

          // Set user ability
          vm.$ability.update(userAbility)

          // Set user's ability in localStorage for Access Control
          localStorage.setItem('userAbility', JSON.stringify(userAbility))

          // We will store `userAbility` in localStorage separate from userData
          // Hence, we are just removing it from user object
          delete user.ability

          // Set user's data in localStorage for UI/Other purpose
          localStorage.setItem('userData', JSON.stringify(user))

          if (remember.value) {
            localStorage.setItem('b', remember.value)
            localStorage.setItem('k', username.value)
            localStorage.setItem('s', password.value)
          }

          // On success redirect to home
          router.push('/')
        }
      } catch (error) {
        // API isteği sırasında bir hata oluşursa, errorTxt'deki mesajı kullan
        errorTxt.value = error.response?.data?.msg || 'Bir hata oluştu. Lütfen tekrar deneyin.'
      } finally {
        loading.value = false
      }
    }

    return {
      handleFormSubmit,
      loading,
      errorTxt,
      isPasswordVisible,
      username,
      password,
      remember,
      errorMessages,
      socialLink,
      icons: {
        mdiEyeOutline,
        mdiEyeOffOutline,
      },
      validators: {
        required,
      },

      // themeConfig
      appName: themeConfig.app.name,
      appLogo: themeConfig.app.logo,

      // Template Refs
      loginForm,
    }
  },
}
</script>

<style lang="scss" scoped>
@import '@core/preset/preset/pages/auth.scss';

.logo-container {
  position: relative;
  height: 100px; /* Konteynerin yüksekliğini sabitleyin */
  margin-top: -40px; /* Yukarı taşı */
}
.logo {
  position: absolute;
  top: -5%; /* Dikey ortalama */
  left: 50%; /* Yatay ortalama */
  transform: translate(-50%, -35%); /* Ortalamayı tamamla */
  width: 300px; /* İstediğiniz genişliği ayarlayın */
  height: auto; /* Oranı koruyarak yüksekliği ayarlayın */
}
.logo-container-dark {
  position: relative;
  height: 100px; /* Konteynerin yüksekliğini sabitleyin */
  margin-top: -20px; /* Yukarı taşı */
}
.logo-dark {
  position: absolute;
  top: -5%; /* Dikey ortalama */
  left: 50%; /* Yatay ortalama */
  transform: translate(-50%, -35%); /* Ortalamayı tamamla */
  width: 300px; /* İstediğiniz genişliği ayarlayın */
  height: auto; /* Oranı koruyarak yüksekliği ayarlayın */
}
</style>
